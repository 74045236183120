<template>
  <div class="thumbnails">
    <div class="banner thumbnails__imgWrap thumbnails__imgWrap_full">
<!--      <img class="banner__img thumbnails__img" src="../assets/img/banners/login-banner.jpg" alt="">-->
    </div>

    <Form action="" v-if="resetModal" class="form form_box" @submit="resetPassword" key="forgot">
      <fieldset>
        <legend class="form__title">Please enter your email</legend>
      </fieldset>

      <label class="form__label">
        <span class="form__labelTitle text_white">E-mail</span>
        <Field type="text" class="form__input"
               name="E-email"
               placeholder="E-mail"
               v-model="user.email"
               rules="required|email"
        />
        <ErrorMessage class="form__error"  name="E-email" />
      </label>

      <p v-if="successText" class="text text_green text_italic">We have emailed you instructions to reset your password.
      </p>

        <div class="form__row">
          <span class="text_green" @click="updateResetModalFalse">Back to Login</span>
        </div>


      <button type="submit" class="btn btn_default btn_transparent"><svg-icon name="solid/arrows-rotate" /> Reset password</button>

    </Form>

    <Form action="" v-else class="form form_box" @submit="login" key="login">
      <fieldset>
        <legend class="form__title">Please enter your login details</legend>
      </fieldset>

      <label class="form__label">
        <span class="form__labelTitle text_white">Username</span>
        <Field type="text" class="form__input"
               name="email"
               placeholder="Username"
               v-model="user.email"
               :rules="{required: !this.resetModal, email: !this.resetModal}"
        />
        <ErrorMessage class="form__error"  name="email" />
      </label>

      <label class="form__label">
        <span class="form__labelTitle text_white">Password</span>
        <Field type="password" class="form__input"
               placeholder="Password"
               name="password"
               v-model="user.password"
               :rules="{required: !this.resetModal}"
        />
        <ErrorMessage class="form__error"  name="password" />

      </label>

      <div class="form__row">
        <label class="form__label form__label_row">
          <input type="checkbox" class="form__checkbox" hidden>
          <span data-v-5d567869="" class="form__checkbox_icon"></span>
          <span class="form__labelTitle">Remember me</span>
        </label>

        <span class="text_green" @click="updateResetModal">Forgot password?</span>
      </div>

      <button type="submit" class="btn btn_default btn_transparent"><svg-icon name="solid/right-to-bracket" />Login</button>
    </Form>
  </div>
</template>

<script>

  import {mapGetters} from 'vuex';
  export default {
    name: "Authentication",
    data() {
      return {
        user: {
          email: '',
          password: '',
        },

        resetPass: {
          sendLogin: true,
          email: '',
        }
      }
    },

    methods: {
      login() {
        /*this.$validator.validate().then(valid => {
          if (valid) {*/
            this.$store.dispatch('auth/login', {
              email: this.user.email,
              password: this.user.password,
            })
         /* }
        });*/
      },

      resetPassword(){
       /* this.$validator.validate().then(valid => {

          if (valid) {*/
            this.$store.dispatch('auth/forgotPass', {
              email: this.user.email
            })
        /*  }
        });*/
      },

      closePopup(){
        return this.$store.dispatch('auth/closeModal');
      },

      updateResetModal(){
        this.$store.commit('auth/updateResetModal', true);
      },

        updateResetModalFalse(){
            this.$store.commit('auth/updateResetModal', false);
            this.$store.commit('auth/successText', false);
        }
    },

    computed: {
      ...mapGetters({
        successText: 'auth/successText',
        openModal: 'auth/status',
        resetModal: 'auth/resetModal',
      }),

      errorsLogin(){
        return this.$store.getters['auth/errorMessage'];
      },

    },

    created(){
      this.$store.commit('auth/updateResetModal', false);
    }
  }
</script>

<style scoped>

</style>
